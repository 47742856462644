// extracted by mini-css-extract-plugin
export var container = "styles-module--container--xMjRl";
export var slimRightSide = "styles-module--slim-right-side--5b+5S";
export var slimTop = "styles-module--slim-top--AGLUL";
export var topSpacer = "styles-module--top-spacer--iBV6n";
export var sideSpacerUpper = "styles-module--side-spacer-upper--brNd4";
export var sideSpacerBetweenElements = "styles-module--side-spacer-between-elements--dYT4Y";
export var afterIcon = "styles-module--after-icon--sgbbw";
export var back = "styles-module--back--xg5hL";
export var leftSpacingColumn = "styles-module--left-spacing-column--n25MT";
export var alwaysHide = "styles-module--always-hide--QjHO3";